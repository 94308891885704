import React from 'react'
import ReactDOM from 'react-dom'
import { Card, Layout } from 'antd'

import LoginForm from './components/Login'

import './index.less'

const { Content } = Layout

ReactDOM.render(
  <Layout>
    <Content>
      <div className="main">
        <LoginForm />
      </div>
    </Content>
  </Layout>,
  document.getElementById('app')
)
