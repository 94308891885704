import React from 'react'
import { Button, Card } from 'antd'
import { WindowsFilled } from '@ant-design/icons'
import './index.less'

const Login: React.FC<{}> = props => {
  return (
    <div className="login">
      <Card>
        <h1>Login</h1>
        <Button 
          icon={<WindowsFilled />}
          href="/auth/azuread"
          type="primary"
        >
          Azure Active Directory
        </Button>
      </Card>
    </div>
  )
}
export default Login
